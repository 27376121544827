import { Button } from 'antd';
import React from 'react';

function Careers() {

  return (
    <div id="careers"  style={{width:"100%",margin:'8% 4%',marginTop:'15%'}} >
      <div className='careers' style={{display:"flex",margin:'0% 4%'}}>
        <img className='careersImg1' style={{height:"266px",width:"400px",marginTop:'-90px',margin:'-90px 0 10px 36px',borderRadius:'4px'}}src='https://d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/40dc7103-kb6-1088-kevinbrownphoto_10b407e00000000000001o.jpg' alt='header-logo'></img>
        <img className='careersImg2' style={{height:"266px",width:"400px",marginTop:'90px',margin:'120px 0 10px 10px',borderRadius:'4px'}}src='https://d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/376bde40-kb6-1369-kevinbrownphoto_10b407f0b407e00000001o.jpg' alt='header-logo'></img>
        <div>
        <div className='careersOpr' style={{width:'600px',margin:'-20px 20px'}}>
              <div style={{fontFamily:"Roboto",fontWeight:'700',fontStyle:'normal', fontSize:"36px",alignItems:'center',textAlign:'center',justifyContent:'center', color:"#3rgb(76, 76, 76)"}}>
              CAREER OPPORTUNITIES
              </div>
              <p  style={{fontFamily:"Roboto",fontStyle:'normal', fontWeight:'400',fontSize:"16px",padding:'10px',lineHeight:'28px',color:"rgb(76, 76, 76)",textAlign:'center',justifyContent:'center'}}>
              It’s said that innovation distinguishes between a leader and a follower, and RumbleOn is leading the tech industry in a whole new direction. It takes the combination of new tech, an old problem, and a big idea to turn heads and that’sjust what we’re doing at RumbleOn. If you’re revving to shake up the industry and revolutionize an outdated online vehicle marketplace, then you’re ready to join us in our vision.             
               </p>
               <Button type="primary" shape='circle' onClick={()=>window.open('https://go.rumbleon.com/careers-at-rumbleon')} target={'_blank'} style={{margin:'6px',background:'rgba(133,206,6,1)',cursor:'pointer',border:'none',height:'58px',padding:'4px', width:'438px',color:'#fff',borderRadius:'4px'}}>
               <p  style={{fontFamily:"Roboto",fontStyle:'normal', fontWeight:'400',fontSize:"18px",lineHeight:'48px',textAlign:'center',justifyContent:'center'}}>APPLY NOW
               </p>
               </Button>
        </div>
        </div>
        </div>
    </div>
  );
}

export default Careers;