import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import About from './Components/About';
import Careers from './Components/Careers';
import CashOffer from './Components/CashOffer';
import GotSpeed from './Components/GotSpeed';
import ContactUs from './Components/ContactUs';
import RumbleOn from './Components/RumbleOn';
import Footer from './Components/footer';

function App() {
  return (
    <div className="App" style={{background:'rgba(238,238,238,1)', minWidth:'2900px'}}>
      <div className="appContainer" style={{marginLeft:'3%',marginRight:'3%',background:'#fff'}}>
      <Header ></Header>
      <CashOffer></CashOffer>
      <About></About>
      <RumbleOn></RumbleOn>
      <GotSpeed></GotSpeed>
      <Careers></Careers>
      <ContactUs></ContactUs>
      {/* <Footer></Footer> */}
      </div> 
    </div>
  );
}

export default App;
