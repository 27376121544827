import React from 'react';

function RumbleOn() {
  return (
    <div id="rumbleon" style={{height:"600px",width:"100%",display:"flex"}} >
         <div  className='rumbleonImgbag'style={{display:'block',background:'rgb(217, 217, 217)', backgroundImage:'url(//d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/a8c8a38c-ro-fairing-transparent_10000000ki0dg002000028.png)',
    backgroundRepeat:'no-repeat',backgroundPosition:'top',marginLeft:"auto",marginRight:'auto', width:'100%',height:'600px'}}>
         <div className='rumbleon' style={{margin:'10% 28%',padding:"30px",fontFamily:"Roboto",fontSize:"18px",color:"rgb(17, 3, 3)"}}>
          <div className='rumbleonContainer' style={{width:'754px'}}>
              <div style={{fontFamily:"Roboto",fontWeight:'100',fontStyle:'normal', fontSize:"24px",alignItems:'center',textAlign:'center',justifyContent:'center', color:"rgb(76, 76, 76)"}}>
              POWERED BY
              </div>
              <img style={{height:"36px",width:"450px",margin:'10px 16%'}}src='https://d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/ec455b23-rumbleon-logo_10ci010000000000000028.png' alt='rumbleon'></img>
              <p  style={{fontFamily:"Roboto",fontStyle:'normal',marginTop:'25px', fontWeight:'400',lineHeight:'24px',fontSize:"16px",color:"rgb(76, 76, 76)",textAlign:'center',justifyContent:'center'}}>
              Wholesale Inc. is proudly powered by RumbleOn. As a publicly-traded (RMBL) top-technology company, RumbleOn is changing the way we buy and sell vehicles of all kinds. At Wholesale Inc., you'll experience a whole new way to buy and sell where the dealers do. 
              </p>
        </div>
        </div>
        </div>

    </div>
  );
}

export default RumbleOn;