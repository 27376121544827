import { Button } from 'antd';
import React from 'react';

function GotSpeed() {
  return (
    <div id="gotspeed" className='gotspeed' style={{height:"100%",width:"100%" ,paddingBottom:'150px',background:'rgba(17,17,17,1)'}} >
      <div className='gotspeedContainer' >    
       <img className='gotspeedCom' style={{height:"75px",width:"450px",borderRadius:'4px',marginTop:'150px'}}src='https://d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/5e41efed-gotspeed-logo-full-color-gotspeed-wordmark-com-fullcolor-flat-dark_10p0046000000000000028.png' alt='header-logo'></img>
        <div className='gotspeedCards' style={{display:"flex",margin:'2% 22% 4%',marginTop:'2%'}}>
          <div className='gotspeedCardOne' style={{marginRight:'20px',marginLeft:'-50px'}}>
          <img style={{height:"171px",width:"300px",borderRadius:'4px'}}src='https://d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/1508b725-gs-img1_10go0b40go09i00000s01o.jpeg' alt='header-logo'></img>
          <div style={{fontFamily:"Roboto",fontWeight:'700',fontStyle:'normal',marginTop:'30px', fontSize:"18px",color:"#ffffff"}}>
          LOOKING TO BUY?
                </div>
          <div className='gotspeedtext' style={{width:'350px',textAlign:'center',alignContent:'center',justifyContent:'center'}}>     
          <p style={{width:'250px',fontFamily:"Roboto",fontWeight:'400',fontStyle:'normal',marginLeft:'50px',lineHeight:'24px',fontSize:"16px",color:"#ffffff"}}>
          Don’t miss out on our wide selection of vehicles, ranging from cars to SUVs, and even luxury vehicles from our sister-site www.Gotspeed.com. Shop online or in-person today!
                </p>
          </div> 
          </div>
          <div className='gotspeedCardTwo' style={{marginRight:'20px',marginLeft:'10px'}}>
          <img style={{height:"171px",width:"300px",borderRadius:'4px'}}src='https://d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/504f0420-gs-img2_10go09i000000000000028.png' alt='header-logo'></img>
          <div style={{fontFamily:"Roboto",fontWeight:'700',fontStyle:'normal',marginTop:'30px', fontSize:"18px",color:"#ffffff"}}>
          OUR INVENTORY
                </div>
          <div className='gotspeedtext'style={{width:'350px',textAlign:'center',alignContent:'center',justifyContent:'center'}}>
          <p style={{width:'250px',fontFamily:"Roboto",fontWeight:'400',fontStyle:'normal',marginLeft:'50px',lineHeight:'24px', fontSize:"16px",color:"#ffffff"}}>
          Whether you’re looking to sell or have the option to buy, don’t miss out on our wide selection of vehicles. We’ve made the shopping process simple and more enjoyable. You can easily browse and shop our extensive inventory nationwide. Find your new ride today!
                </p>
          </div>
          </div>
          <div className='gotspeedCardThree' style={{marginRight:'30px',marginLeft:'10px'}}>
          <img style={{height:"171px",width:"300px",borderRadius:'4px'}}src='https://d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/68019581-gs-img3_10m20ce0go09i02q01g01o.jpeg' alt='header-logo'></img>
          <div style={{fontFamily:"Roboto",fontWeight:'700',fontStyle:'normal',marginTop:'30px', fontSize:"18px",color:"#ffffff"}}>
          FINANCING
                </div>
          <div className='gotspeedtext' style={{width:'350px',textAlign:'center',alignContent:'center',justifyContent:'center'}}>
          <p style={{width:'250px',fontFamily:"Roboto",fontWeight:'400',marginLeft:'50px',lineHeight:'24px',fontStyle:'normal', fontSize:"16px",color:"#ffffff"}}>
          Our finance specialists have years of experience helping customers with all types of credit history, and we are here to get you one step closer to driving away in your vehicle! Simply fill out a credit application and let our auto financing experts take care of the rest!
                </p>
                </div>     
          </div>
        </div>
        <Button  type="primary" shape='circle' onClick={()=>window.open('https://www.gotspeed.com/used-vehicles/')} target={'_blank'} style={{margin:'-230px 0px',background:'rgba(14,111,184,1)',cursor:'pointer',border:'none',height:'58px',padding:'4px', width:'438px',color:'#fff',borderRadius:'4px'}}>
        <p  style={{fontFamily:"Roboto",fontStyle:'normal', fontWeight:'400',fontSize:"18px",lineHeight:'48px',textAlign:'center',justifyContent:'center'}}>
                BROWSE INVENTORY
              </p>
        </Button>
        </div>
    </div>
  );
}

export default GotSpeed;