import React from 'react';

function About() {
  return (
    <div id="about" style={{height:"600px",width:"100%",display:"flex"}}>
        <div className='about' style={{margin:'9% 28%',padding:"30px",fontFamily:"Roboto",fontSize:"18px",color:"rgb(17, 3, 3)"}}>
          <div  className='aboutContainer' style={{width:'754px'}}>
              <div style={{fontFamily:"Roboto",fontWeight:'700',fontStyle:'normal', fontSize:"34px",color:"#333333",textAlign:'center',justifyContent:'center'}}>
              #1 PLACE TO BUY & SELL
              </div>
              <div style={{fontFamily:"Roboto",fontWeight:'700',fontStyle:'normal', fontSize:"34px",color:"#333333",textAlign:'center',justifyContent:'center'}}>
              VEHICLES AT WHOLESALE PRICES
              </div>
              <p  style={{fontFamily:"Roboto",fontWeight:'400',lineHeight:'26px', fontSize:"16px",color:"rgb(17, 3, 3)",textAlign:'center',justifyContent:'center'}}>
              We continue to lead the industry as the go-to resource for buying and selling any vehicle at a wholesale price, now made available to the public. As the largest vehicle wholesaler, we buy and sell thousands of vehicles nationwide. Whether you're looking to trade in your car for cash or find the perfect vehicle,Wholesale Inc. has you covered. 
              </p>
        </div>
        </div>
    </div>
  );
}

export default About;