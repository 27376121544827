import React from 'react';
// import { Link } from 'react-router-dom';
import { Link } from 'react-scroll';

function Header() {
  return (
    <div style={{display:"flex"}} >
      {/* <header className="App-header"> */}
      <div className="header" style={{display:"flex",margin:'28px'}}>
          <div style={{alignContent:'flex-start'}}>
         <img style={{height:"86px",width:"203px",marginLeft:'22px',marginBottom:'4px',marginTop:'4px'}}src='https://d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/13ba9f2a-wsi-wordmark-rgb-fullcolor_10ba04s000000000000028.png' alt='header-logo'></img>
         </div>
         <div style={{alignContent:"flex-end"}}>  
      <div className="headerNav" style={{display:"flex",marginLeft:'820px',marginTop:'40px'}} >
          <div  style={{width:'125px',height:'22px',lineHeight:'20px',fontFamily:"Roboto",fontSize:"18px",borderRight:'1px solid',cursor:'pointer'}}><Link to="cashoffer" spy={true} smooth={true} duration={50} style={{color:"rgb(17, 3, 3)"}}> Cash Offer</Link></div>
          <div style={{width:'125px',height:'22px',lineHeight:'20px',fontFamily:"Roboto",fontSize:"18px",borderRight:'1px solid',cursor:'pointer'}}><Link to="about" spy={true} smooth={true} duration={50} style={{color:"rgb(17, 3, 3)"}}> About</Link></div>
          <div style={{width:'125px',height:'22px',lineHeight:'20px',fontFamily:"Roboto",fontSize:"18px",borderRight:'1px solid',cursor:'pointer'}}><Link to="gotspeed"  spy={true} smooth={true} duration={50} style={{color:"rgb(17, 3, 3)"}}> GotSpeed</Link></div>
          <div style={{width:'125px',height:'22px',lineHeight:'20px',fontFamily:"Roboto",fontSize:"18px",borderRight:'1px solid',cursor:'pointer'}}><Link to="careers"  spy={true} smooth={true} duration={50} style={{color:"rgb(17, 3, 3)"}}>Careers</Link></div>
          <div style={{width:'125px',height:'22px',lineHeight:'20px',fontFamily:"Roboto",fontSize:"18px",cursor:'pointer'}}><Link to="contactus"  spy={true} smooth={true} duration={50} style={{color:"rgb(17, 3, 3)"}}>Contact Us</Link></div>
      </div>
      </div>
      </div>
      
      {/* </header> */}
    </div>
  );
}

export default Header;
