import { Button, Input } from 'antd';
import React from 'react';

function CashOffer() {

  function handleurl() {
    window.open('https://www.rumbleon.com/how-to/sell-your-vehicle');
  }
  return (
    <div id="cashoffer" style={{ height:"600px",display:"flex"}} >
       <div style={{display:'block',background:'rgba(51,70,68,1)',backgroundImage:'url(//d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/5938019c-wsi-landing-page-hero_11e00s51e00go000000028.png)',
    backgroundRepeat:'no-repeat',backgroundPosition:'center top',marginLeft:"auto",marginRight:'auto', width:'100%',height:'600px'}}>
        <div className='cashoffer' style={{display:'flex', margin:'90px 0px 90px 340px'}}>
        <div className='cashofferContainer' style={{alignContent:'flex-start',width:'500px',margin:'98px 30px',textAlign:'start'}}>
                <div className='cashofferPara' style={{fontFamily:"Roboto",fontWeight:'700',fontStyle:'normal', lineHeight:'44px',fontSize:"44px",color:"#ffffff"}}>
                GET YOUR FREE
                </div>
                <div className='cashofferPara' style={{fontFamily:"Roboto",fontWeight:'700',fontStyle:'normal', fontSize:"44px",color:"#ffffff"}}>
                CASH OFFER TODAY
                </div>
                <p  className='cashoffertext' style={{fontFamily:"Roboto",fontWeight:'400',fontStyle:'normal',paddingTop:'20px', fontSize:"16px",color:"#ffffff"}}>
                The most hassle-free way to get cash for any vehicle FAST!
                </p>
                <p  className='cashoffertext'style={{width:'365px',fontFamily:"Roboto",fontWeight:'400',fontStyle:'normal', lineHeight:'26px',fontSize:"16px",color:"#ffffff"}}>
                We make the process of selling your vehicle quick, transparent, and safe–all online–anywhere you are.
                </p>
        </div>
        <div className='getoffer' style={{padding:'40px',margin:'184px 34px',borderRadius:'4px'}}>
            
            <div>
             {/* <p style={{ margin:'0 6px',fontFamily:"Roboto",fontWeight:'BOLD',fontStyle:'normal', fontSize:"16px",color:"#000",textAlign:'start'}} >
             ENTER YOUR VIN TO GET YOUR OFFER
              </p>
            <Input   style={{margin:'6px',background:'#fff',border: '#afafaf',boxShadow: '1px 0px 3px',height:'50px',padding:'6px',paddingLeft:'16px', width:'400px',borderRadius:'4px',outline:'none'}} placeholder='VEHICLE IDENTIFICATION NUMBER'  ></Input> */}
            <div>
            {/* <button  style={{background:'rgba(225,37,27,1)',minWidth:'200px'}}> GET YOUR OFFER</button> */}
            <Button type="primary" shape='circle' onClick={handleurl} target={'_blank'} style={{margin:'6px',background:'rgba(225,37,27,1)',cursor:'pointer',border:'none',height:'58px',padding:'18px', width:'400px',color:'#fff',borderRadius:'4px'}}> 
             <p  style={{fontFamily:"Roboto",fontStyle:'normal', fontWeight:'400',fontSize:"18px",lineHeight:'18px',textAlign:'center',justifyContent:'center'}}>
                GET YOUR OFFER
              </p></Button>
            </div>
            </div>
        </div>
        </div>
        </div>
    </div>
  );
}

export default CashOffer;