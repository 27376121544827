import React from 'react';

function ContactUs() {
  return (
    <div id="contactus" style={{height:"400px",width:"100%",background:'rgba(43,43,43,1)'}} >
      <div style={{paddingTop:'62px'}}>
      <div  className='contactus' style={{display:"flex",margin:'0px',marginRight:'30%',marginLeft:'30%'}}>
          <div className='contactusImg'style={{alignContent:'flex-start',margin:'44px 40px',marginLeft:'51px'}}>
         <img style={{height:"136px",width:"319px"}} src='//d9hhrg4mnvzow.cloudfront.net/get.rumbleon.com/wsi/5581fd9d-wsi-wordmark-rgb-allwhite_108v03s000000000000028.png' alt='contact-us'></img>
         </div>
         <div style={{alignContent:"flex-end",marginTop:'24px',marginLeft:'5px'}}>  
      <div className='contactusTxt' style={{ textAlign:'start'}}>
      <p style={{fontFamily:"Roboto",fontWeight:'400',fontStyle:'normal', fontSize:"16px",color:"#ffffff"}}>
      Contact Us: 615-449-0388
      </p>
      <p style={{fontFamily:"Roboto",fontWeight:'300',fontStyle:'normal', fontSize:"14px",color:"#ffffff"}}>
      8037 Eastgate Blvd, Mt. Juliet, TN, 37122
      </p>
      <p style={{fontFamily:"Roboto",fontWeight:'300',fontStyle:'normal', fontSize:"14px",color:"#ffffff"}}>
      Monday-Friday: 8 a.m. - 7 p.m.
      </p>
      <p style={{fontFamily:"Roboto",fontWeight:'300',fontStyle:'normal', fontSize:"14px",color:"#ffffff"}}>
      Saturday: 8 a.m. - 5 p.m.
      </p>
      <p style={{fontFamily:"Roboto",fontWeight:'300',fontStyle:'normal', fontSize:"14px",color:"#ffffff"}}>
      Sunday: Noon - 5 p.m.
      </p>
      </div>
      </div>
      </div>
      <div className='contactus-rights' style={{display:"flex",marginRight:'38%',marginLeft:'42%'}}>
      <p style={{fontFamily:"Roboto",fontWeight:'400',fontStyle:'normal', fontSize:"16px",color:"#ffffff"}}>
      © 2022 Wholesale Inc. All rights reserved.
      </p>
      </div>
      </div>
    </div>
  );
}

export default ContactUs;